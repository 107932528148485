import React, { useContext, useEffect } from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import { colorModes } from "../../../utils/common";
import { Context } from "../../../context/Context";

import * as styles from "./Header.module.scss";

const Header = ({ className, location }) => {
  const { colorMode, setColorMode } = useContext(Context);

  // useEffect(() => {
  //   setColorMode(colorModes[1]);
  // }, []);

  const link = location.pathname.split("/")[1];

  // const cls = classNames({ [styles.darkMode]: !link });
  const cls = classNames({ [styles.lightMode]: !link });

  return (
    <header className={cls}>
      <h2 id="mainmenulabel" className="visually-hidden">
        Main Menus
      </h2>
      <nav aria-labelledby="mainmenulabel" className={styles.nav}>
        <ul>
          <li className={styles.name}>
            <Link to="/" activeClassName="active">
              Alistair McClymont
            </Link>
          </li>
          <li>
            <Link to="/artwork" activeClassName="active">
              artwork
            </Link>
          </li>
          <li>
            <Link to="/studio" activeClassName="active">
              studio
            </Link>
          </li>
          <li>
            <Link to="/biography" activeClassName="active">
              biography
            </Link>
          </li>
          <li>
            <Link to="/contact" activeClassName="active">
              contact
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
};

export { Header };
